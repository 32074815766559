.logo{
    width: 200px !important;
    height: 57px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.ecolon-logo{
    background: url('../images/ecolon-logo.png');
}

.colon-seguros-logo{
    background: url('../images/colon-seguros-logo.png');
}

.colon-asistencias-logo{
    background: url('../images/colon-asistencias-logo.png');
}

.ui.table thead th{
    background-color: white !important; 
}

.section-title{
    height: 35px;
    //background: red;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 7px;
    margin-bottom: 25px;
}

.form-large{
    width: 100%;
    margin-bottom: 15px;
}

.buttons-align-right{
    text-align: right;
}

.buttons-submit-right{

    text-align: right;
}

.button-submit{
    width: 100%;
    @media only screen and (min-width: 600px) {
        width: 200px;
    }
}

.button-change-password-container{
    @media only screen and (min-width: 600px) {
        display: inline-flex;
        width: 50%;
    }
}

.button-change-password{
    width: 100%;
}

.react-datepicker-wrapper{
    width: 100%;
}

.component-error{
    display: block;
    background: red;
    width: 100%;
}

.ui .checkbox{
    height: 60px;
    font-weight: bold;
    label{
        padding-left: 0px !important;
    }
}

.ui.checkbox .box:before, .ui.checkbox label:before{
    top: 30px !important;
    left: 0px !important;
    width: 25px !important;
    height: 25px !important;
}

.ui.checkbox .box:after, .ui.checkbox label:after{
    top: 34px !important;
    left: 0px !important;
    width: 25px !important;
    height: 25px !important;
    font-size: 20px !important;
}

.boton-ver-coberturas{
    height: 35px;
    margin-top: 24px !important;
}



.success-label{
    width: 30%;
    text-align: center;
}

.success-item{
    width: 30%;
    display: inline;
}

.success-label-container{
    text-align: left;
    font-weight: bold;
    font-size: 1.0em;
    margin: 10px;
    left: 30px
}

/*DARK MODE*/
/*#root{
    background: grey !important;
    background-color: grey !important;
    .ui.top.attached.menu{
        background: grey !important;
        background-color: grey !important;
    }
    input{
        background: grey !important;
        background-color: grey !important;
    }
    .ui.selection.dropdown{
        background: black !important;
        background-color: black !important;
        .text{
            color: grey !important;
        }
        .visible{
            .text{
                color: black !important;
            }
        }
    }
    .visible.menu.transition{
        background: grey !important;
        background-color: grey !important;
    }
    .ui.stacked.segment{
        background: grey !important;
        background-color: grey !important;
    }
    .ui.pointing.above.prompt.label{
        background: grey !important;
        background-color: grey !important;
    }
}*/

    .create-new-user-labels{        
        @media only screen and (min-width: 1900px) {
            width: 32%;
            display: flex;
            position: absolute;
            left: 33%;
        }
        @media only screen and (min-width: 600px) and (max-width: 1800px) {
            width: 50%;
            display: flex;
            margin-left: 330px !important;
        }
    }
.disabled{
    opacity: 1 !important;
    .selection{
        background: #e4e4e4 !important;
    }
    input{
        background: #e4e4e4 !important;
    }
}

.container-home{
    text-align: center;
    padding: 0px;
}
/*
.image-home{
    display: inline;
    @media only screen and (max-width: 600px) {
        margin: 0px !important;
        padding: 0px !important;
        width: 100%;
    }
    
    @media only screen and (min-width: 600px) {
        
        width: 30%;
    }
    
}
*/

.home-page{
    padding: 0px !important;
    margin:  0px !important;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100% !important;
    top: 0px !important;
    padding-top: 65px !important;
}

.body-container{
    margin-top: 30px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 30px !important;
    min-height: calc(100vh - 233px) !important;  
}

.body-container-show{
    .home-page{
        z-index: 0 !important;
    }
}

.body-container-hide{
    .home-page{
        z-index: -1 !important;
    }
}

.footer{
    padding-top: 20px !important;
    //background-color: rgba(249, 226, 226, 0.87);
    text-align: center;
    padding-bottom: 10px;
    padding-left: 0px !important;
    margin-top: 0px !important;
    //bottom: 0pt;
    //left: 0px;
    width: 100%;
    position: relative;
    display: flex;
}

.form-centered{
    width: 40% !important;
    margin-left: 30% !important;
    @media only screen and (max-width: 600px) {
        margin-left: 0px !important;
        width: 100% !important;
    }
}

.title-header{
    margin: 0px !important;
    margin-bottom: 10px !important;
}

.footer-bottom{
    width: 100%;
    margin-bottom: 20px;
}

.accordion{
    border-top: solid 1px black !important;
    height: 100px !important;
    width: 100%;
}

.hide-accordion{
    td{
        display: none !important;
        height: 0px !important;
    }
    //display: none;
    height: 0px !important;
}

.show-accordion{
    display: table-row !important;
}

.email-list{
    word-wrap: break-word;
    font-size: 11px;
}

.ui.grid{
    margin-bottom: 0px !important;
}

.grid-header{
    background-color: lightblue !important;
    color: rgba(0,0,0,.87);
    cursor: auto;
    background: #f9fafb;
    text-align: inherit;
    color: rgba(0,0,0,.87);
    padding: .92857143em .78571429em;
    vertical-align: inherit;
    font-style: none;
    font-weight: 700;
    text-transform: none;
    border: 1px solid rgba(34,36,38,.15);
    font-size: 1em;
    border-radius: .28571429rem;
    .column{
        border-left: 1px solid rgba(34,36,38,.1);
        width: auto;
    }
}

.accordion-container{
    //background-color: cadetblue;
    display: table-row !important;
}

.accordion-cell{
    width: 30%;
    
    position: relative;
    display: inline-block;
}

.accordion-label{
    position: relative;
    display: inline-block;
    text-align: left;
    width: 50%;
    font-weight: bold;
    padding: 10px;
    vertical-align: -webkit-baseline-middle;
}

.accordion-value{
    position: relative;
    display: inline-block;
    text-align: right;
    width: 50%;
    padding: 10px;
    vertical-align: -webkit-baseline-middle;
}

.error-custom{
    display: block !important;
}

.accordion-icon-open{
    transition: all 1.1s;
    -wekit-transition : all .1s;
    -moz-transition : all .1s;
}

.accordion-icon-close{
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: all 1.1s;
    -wekit-transition : all .1s;
    -moz-transition : all .1s;
}

.tbody-list{
    font-size: smaller;
    cursor: pointer;
}

@import "notification-styles.scss";
@import "check-box.scss";
@import "menu-styles.scss";
@import "modal-styles.scss";
@import "selectize.scss";
//@import "petshop-theme.scss";